import React from 'react';
import bina from "../../assets/images/Homepage/bina.png";
import dapp from "../../assets/images/Homepage/bloomberg.svg";
import bscs from "../../assets/images/Homepage/bscs.png";
import dapp_b from "../../assets/images/Homepage/dapp_b.png";
import medi from "../../assets/images/Homepage/medi.png";

export const CompaniesList = () => {
  return (
    <>
      <ul className="companies-ul">
        {
          logoList.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.item_link} target="/">
                  <img className={item.invert ? "invert" : ""} src={item.image} alt="logo" />
                </a>
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

const logoList = [
  {
    id: 1,
    item_link: "https://www.binance.com/en",
    image: bina
  },
  {
    id: 2,
    item_link: "https://www.bloomberg.com/press-releases/2023-03-01/the-animal-farm-is-releasing-new-utilities-to-support-existing-products",
    image: dapp,
    invert: true
  },
  {
    id: 3,
    item_link: "https://bscscan.com/address/0x2ce4ae0e7d05bc6ec0c22cdf87ff899872c2cf7f",
    image: bscs
  },
  {
    id: 4,
    item_link: "https://finance.yahoo.com/news/animal-farm-challenging-centralized-standard-140000177.html",
    image: dapp_b
  },
  {
    id: 5,
    item_link: "https://medium.com/@f0r3x_shark/animal-farm-governance-b223bc3f6330",
    image: medi
  },
]