import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import video_Banner from '../assets/images/Homepage/video_Banner.png';
import { CompaniesList } from './common/CompaniesList';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { Button } from "./common/Button";

const HeroSec = () => {

  const videoSrc = 'https://learn.shayhowe.com/assets/misc/courses/html-css/adding-media/earth.mp4';
  return (
    <>
      <section className='hero_wrapper'>
        <Container>
          <div className='hero_row'>
            <div className='hero_heading'>
              <h1 className='hero_heading_h1'>The first truly <span className='hero_heading_highlight'>decentralized</span> yield aggregating platform</h1>
              <h3 className='hero_heading_h3'>Participate in the Animal Farm ecosystem and reap incredible benefits from it’s features</h3>
              <Button btnname="Launch App" href="https://defi.animalfarm.world" />
            </div>
            <div className='hero_video'>
              {/* <Video
                                loop
                                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                poster={video_Banner}
                                className={'VideoBLock'}
                                onCanPlayThrough={() => {}}
                            >
                                <source src={videoSrc} type='video/mp4' />
                            </Video> */}
              <iframe
                src='https://youtube.com/embed/JiAzGp-bkAY'
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='video'
                x-frame-options='ALLOWALL'
                className='hero_iframe'
              />
            </div>
          </div>
        </Container>
        <div className="animals_divider"></div>
        <div className='companies_list'>
          <Container>

            <CompaniesList />
          </Container>
        </div>

      </section>
    </>
  );
};

export default HeroSec;
