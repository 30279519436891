import React from 'react';
import Container from 'react-bootstrap/Container';
import { WebHeading } from './common/WebHeading';
import paladin from '../assets/images/Homepage/paladin.png';
import truthSeekers from '../assets/images/Homepage/truthseekers.png';

const OurAuditors = () => {
  return (
    <>
      <section className='ourauditors_sec sem_pading'>
        <Container>
          <WebHeading WebHeading="Our Auditors" />
          <div className='ourauditors_logos'>
            <ul>
              <li>
                <a href="https://paladinsec.co/projects/manorfarm/" target="/"><img src={paladin} alt="paladin" /></a>
              </li>
              <li>
                <a href="https://pdfhost.io/v/n8VsEgZ0N_updated" target="/"><img src={truthSeekers} alt="truth seekers" /></a>
              </li>
            </ul>
          </div>
        </Container>
      </section>
    </>
  )
}

export default OurAuditors;