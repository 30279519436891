import React from 'react';
import { LatestMarketData } from '../../data/LatestMarketData';

export const LatestMarketCard = ({ num }) => {
  const [lan] = React.useState(num);

  return (
    <>
      {
        LatestMarketData.slice(0, lan).map((item, index) => {
          return (
            <div className='col-lg-4 col-md-6 col-12' key={index}>
              <div className='LatestMarket_card'>
                <a href={item.card_link} target="/" ><img src={item.card_img} alt="card_img" /></a>
                <a href={item.card_link} target="/" ><h5>{item.heading}</h5></a>
                {/*<div className='avatar_img_block'>*/}
                {/*    <img src={item.avatar_img} alt="avatar_img" />*/}
                {/*    <span>{item.avatar_name}</span>*/}
                {/*</div>*/}
                <p>{item.card_date}</p>
              </div>
            </div>
          )
        })
      }
    </>
  )
}
