import React from 'react';
import HomePage from './Pages/HomePage';
import TopSiteBanner from './Components/TopSiteBanner';

function App() {
  return (
    <>
      <TopSiteBanner />
      <HomePage />
    </>
  );
}

export default App;
