import React from 'react';
import Container from 'react-bootstrap/Container';
import 'react-html5video/dist/styles.css';
import LaptopBook_Pro from '../assets/images/Homepage/LaptopBook_Pro.png';
import { WebHeading } from './common/WebHeading';

const VisitMarmarSec = () => {
  return (
    <>
      <section className='visit_marmar'>
        <Container>
          <div className='laptop_block_main'>
            <div className='laptop_block'>
              <img src={LaptopBook_Pro} alt='LaptopBook_Pro' />
              <div className='laptop_video'>
                <iframe
                  src='https://youtube.com/embed/3-I3BRntAfQ'
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  x-frame-options='ALLOWALL'
                  className='VideoBLock'
                />
              </div>
            </div>
          </div>
          <div className='visit_paragraph'>
            <br />
            <br />
            <br />
            <br />
            <WebHeading WebHeading='Liquidity Management Tools' />
            <p>
              Our soon-to-be-launched product, the fine-tuned range optimization market-making protocol, is set to revolutionize PancakeSwap V3 migration. This
              tool, backed by market volatility, historical data, and order book insights, allows users to maximize their liquidity management with precision.
              It adapts to real-time price action, offering a competitive edge in trading fee collection paid out in top assets. Moreover, Animal Farm uniquely
              adjusts the emissions of our native assets, AFP and AFD, based on asset demand and liquidity pool performance, ensuring optimal price performance
              in all market conditions.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default VisitMarmarSec;
