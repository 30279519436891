import React from 'react';
import twitter from '../../assets/images/socials/twitter.png';
import discord from '../../assets/images/socials/discord.png';
import telegram from '../../assets/images/socials/telegram.png';
import medium from '../../assets/images/socials/medium.png';
import youtube from '../../assets/images/socials/youtube.png';

export const Socials = (props) => {
  return (
    <div className='socials'>
      <div>
        <a href='https://twitter.com/animalfarmdefi' target='_blank' rel='noreferrer'>
          <img src={twitter} alt='' />
        </a>
      </div>
      <div>
        <a href='https://discord.gg/PPcuC8desY' target='_blank' rel='noreferrer'>
          <img src={discord} alt='' />
        </a>
      </div>
      <div>
        <a href='http://t.me/The_Animal_Farm' target='_blank' rel='noreferrer'>
          <img src={telegram} alt='' />
        </a>
      </div>
      <div>
        <a href='https://medium.com/@f0r3x_shark' target='_blank' rel='noreferrer'>
          <img src={medium} alt='' />
        </a>
      </div>
      <div>
        <a href='https://www.youtube.com/channel/UCrQIRRiwHoAjb0UeKi2PryQ' target='_blank' rel='noreferrer'>
          <img src={youtube} alt='' />
        </a>
      </div>
    </div>
  )
}


