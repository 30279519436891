import React from 'react';
import Container from 'react-bootstrap/Container';
import { WebHeading } from './common/WebHeading';
import bina from "../assets/images/Homepage/bina.png";
import coinGape from "../assets/images/Homepage/coinGape.png";
import dextools from "../assets/images/Homepage/dextools.png";
import beincrypto from "../assets/images/Homepage/beincrypto.png";
import bscnews from "../assets/images/Homepage/bscnews.svg";
import dapp from "../assets/images/Homepage/dapp.png";
import bscs from "../assets/images/Homepage/bscs.png";
import dapp_b from "../assets/images/Homepage/dapp_b.png";
import dapp_c from "../assets/images/Homepage/bloomberg.svg";

const OurPartners = () => {
  return (
    <>
      <section className='OurPartners_sec sem_pading'>
        <Container>
          <WebHeading WebHeading="Our Partners" />
          <div className='OurPartners_logos'>
            <ul>
              {
                OurPartnersData.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.item_link} target="/">
                        <img className={item.invert ? "invert" : ""} src={item.image} alt="logo" />
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </Container>
      </section>
    </>
  )
}

export default OurPartners;


const OurPartnersData = [
  {
    id: 1,
    image: dapp,
    item_link: "https://news.bitcoin.com/taking-yield-farming-to-the-next-level-with-animal-farm/"
  },
  {
    id: 2,
    image: bscnews,
    item_link: "https://www.bsc.news/post/drip-protocol-prepares-to-launch-animal-farm-v2"
  },
  {
    id: 3,
    image: dapp_b,
    item_link: "https://finance.yahoo.com/news/animal-farm-challenging-centralized-standard-140000177.html"
  },
  {
    id: 4,
    image: bina,
    item_link: "https://www.binance.com/en"
  },
  {
    id: 5,
    image: bscs,
    item_link: "https://bscscan.com/address/0x2ce4ae0e7d05bc6ec0c22cdf87ff899872c2cf7f"
  },
  {
    id: 6,
    image: coinGape,
    item_link: "https://coingape.com/press-releases/animal-farm-a-fully-decentralized-ownership-yield-farm-lending-protocol/"
  },
  {
    id: 7,
    image: dextools,
    item_link: "https://www.dextools.io/app/bnb/pair-explorer/0x2ce4ae0e7d05bc6ec0c22cdf87ff899872c2cf7f"
  },
  {
    id: 8,
    image: beincrypto,
    item_link: "https://beincrypto.com/animal-farm-a-sustainable-yield-aggregation-platform/"
  },
  {
    id: 9,
    item_link: "https://www.bloomberg.com/press-releases/2023-03-01/the-animal-farm-is-releasing-new-utilities-to-support-existing-products",
    image: dapp_c,
    invert: true
  },
]