import React from 'react';
import Container from 'react-bootstrap/Container';
import logo from "../assets/images/Homepage/logo.png";
import { Socials } from '../Components/common/Socials'

const Header = () => {
  return (
    <>
      <header className='header_wrapper'>
        <Container>
          <div className='row'>
            <div className='col-6 col-lg-4 header-col'>
              <div className='logo_block'>
                <a href="https://defi.animalfarm.app">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            <div className='col-6 col-lg-4 header-col header_links order-lg-2'>
              <a className='header_link' href='https://animalfarm.wiki/'>Docs &amp; Whitepaper</a>
              <a className='header_link' href='https://x87ziggy.github.io/docs/AnimalFarm_TruthSeekers_Audit.pdf'>Audit Report</a>
            </div>
            <div className='col-12 col-lg-4 header-col order-lg-1'>
              <Socials></Socials>
            </div>
          </div>
        </Container>
      </header>
    </>
  )
}

export default Header;