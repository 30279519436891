import React from 'react';
import { OurClientData } from '../../data/OurClientData';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { EffectCoverflow, Pagination, Navigation } from 'swiper';

export const ClientsCard = ({ ouritem }) => {
  const [ournum] = React.useState(ouritem);

  return (
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        slideToClickedSlide={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        slide
        coverflowEffect={{
          rotate: 0,
          stretch: -320,
          depth: 800,
          modifier: 1,
          slideShadows: false,
          // scale: 0.95,
        }}
        navigation={true}
        loop={true}
        pagination={false}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className='mySwiper'
      >
        {OurClientData.slice(0, ournum).map((item, index) => {
          return (
            <SwiperSlide>
              <div className='clients_details'>
                <p>&#8220;{item.details}&#8221;</p>
                <div className='user_block'>
                  <img src={item.userimage} alt='userimage' width={46} height={46} />
                  <div className='user_text'>
                    <p>{item.uesremail}</p>
                    <span>{item.platform}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};
