import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <Container>
          <div className='copyright_block'>
            <p>Copyright © 2022 Animal Farm. All Rights Reserved.</p>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer;