import React from 'react';
import Container from 'react-bootstrap/Container';
import { WebHeading } from './common/WebHeading';
import { ClientsCard } from './common/ClientsCard';

const OurClientsSec = () => {
  return (
    <>
      <section className='our_clients_Sec'>
        <WebHeading WebHeading='What Our Investors Say' />
        <div className='our_clients_card'>
          <div className='card_center_row'>
            <ClientsCard ouritem={6} />
          </div>
        </div>
      </section>
    </>
  );
};

export default OurClientsSec;
