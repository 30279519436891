import React from 'react';

export const WebHeading = (props) => {
  return (
    <>
      <div className='web_hadding_main'>
        <h2 className='web_hadding'>{props.WebHeading}</h2>
      </div>
    </>
  )
}
