import React, { useEffect, useRef, useState } from 'react';
import cancel from '../assets/images/Homepage/cancel.png';

function TopSiteBanner() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        ref.current.setAttribute('style', `height:${ref.current.children[0].clientHeight}px`);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isVisible ? (
    <div className='banner_wrapper' ref={ref}>
      <div className={'banner_TopSiteBanner banner_launched'}>
        <div className='banner_content'>
          <div className='banner_countdown'>
            Animal Farm GOVERNANCE is now LIVE! <a href='https://defi.animalfarm.app/swap'>Check it out here</a>
          </div>
          <div className='banner_close' onClick={() => setIsVisible(false)}>
            <img src={cancel} alt='' />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default TopSiteBanner;
