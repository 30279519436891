import React from 'react';
import Container from 'react-bootstrap/Container';
import cup_img from '../assets/images/Homepage/cup_img.png';
import { Button } from './common/Button';
import { WebHeading } from './common/WebHeading';

const RewardingLoyaltySec = () => {
  return (
    <>
      <section className='rewarding_loyalty_Sec'>
        <Container>
          <div className='rewarding_row'>
            <div className='rewarding_loyalty_text'>
              <WebHeading WebHeading='Loyalty & User Centric Benefits' />
              <p>
                At Animal Farm, we champion a system where aligned incentives foster genuine value. Our platform offers users a pathway to participate actively
                as owners, ensuring everyone benefits from our growth. Instead of typical payouts to developers and founders, our loyalty system utilizes an
                innovative NFT vesting model that motivates users to utilize our native assets (AFD & AFP) on our platform to earn rewards in BNB and BUSD. This
                approach not only promotes platform engagement but also emphasizes the potential of AFD & AFP as valuable assets within our ecosystem.
              </p>
              <Button btnname='Launch App' href='https://defi.animalfarm.app' />
            </div>
            <div className='rewarding_loyalty_image'>
              <div className='rewarding_loyalty_icon'>
                <ul>
                  {/*<li>*/}
                  {/*    <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwj4mJzP0Y76AhVl-zgGHclcD0MQ-TAoAHoECEYQAQ&url=https%3A%2F%2Fwww.facebook.com%2F&usg=AOvVaw2q27ov2cpCynr72DmuSqEz" target="/">*/}
                  {/*        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*            <path d="M5.04571 15.2798V8.64995H7.30417L7.63988 6.05416H5.04571V4.40074C5.04571 3.65169 5.25644 3.13884 6.34198 3.13884H7.71747V0.824548C7.04821 0.753519 6.3755 0.719222 5.70242 0.721817C3.70615 0.721817 2.33556 1.92871 2.33556 4.14432V6.0493H0.0917969V8.6451H2.34046V15.2798H5.04571Z" fill="white" />*/}
                  {/*        </svg>*/}
                  {/*    </a>*/}
                  {/*</li>*/}
                  <li>
                    <a href='https://twitter.com/animalfarmdefi' target='/'>
                      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M10.8375 3.93125C10.2991 3.93116 9.78227 4.14242 9.3981 4.51957C9.01393 4.89672 8.79318 5.40961 8.78334 5.94787L8.7635 7.0635C8.76234 7.1234 8.74853 7.18238 8.72297 7.23656C8.69742 7.29075 8.66069 7.33892 8.61521 7.37791C8.56973 7.41691 8.51651 7.44585 8.45906 7.46283C8.40161 7.47981 8.34121 7.48446 8.28184 7.47646L7.17613 7.32629C5.72121 7.12796 4.32721 6.45787 2.98988 5.34367C2.5663 7.68825 3.39363 9.31246 5.38617 10.5655L6.62363 11.3432C6.68243 11.3802 6.73127 11.431 6.7659 11.4912C6.80052 11.5514 6.81987 11.6191 6.82224 11.6885C6.82462 11.758 6.80995 11.8269 6.77953 11.8893C6.7491 11.9517 6.70384 12.0057 6.64771 12.0466L5.52005 12.8704C6.19084 12.9122 6.82763 12.8825 7.35605 12.7776C10.698 12.1104 12.92 9.59579 12.92 5.44779C12.92 5.10921 12.2032 3.93125 10.8375 3.93125ZM7.36667 5.92167C7.37903 5.23887 7.59255 4.57489 7.98048 4.01286C8.36842 3.45084 8.91353 3.01574 9.54757 2.76205C10.1816 2.50836 10.8764 2.44735 11.5449 2.58665C12.2135 2.72596 12.8261 3.05939 13.306 3.54521C13.8097 3.54167 14.2382 3.66917 15.1966 3.08833C14.9593 4.25 14.8424 4.75433 14.3367 5.44779C14.3367 10.8609 11.0096 13.493 7.63371 14.1667C5.31888 14.6285 1.95288 13.8699 0.988129 12.8626C1.47971 12.8244 3.47721 12.6097 4.6318 11.7647C3.655 11.1208 -0.233038 8.83292 2.32192 2.68175C3.52113 4.08212 4.73734 5.03554 5.96984 5.54129C6.79009 5.87775 6.99125 5.87067 7.36738 5.92237L7.36667 5.92167Z'
                          fill='white'
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href='https://discord.gg/PPcuC8desY' target='/'>
                      <svg width='16px' height='16px' viewBox='0 -28.5 256 256' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z'
                          fill='white'
                          fill-rule='nonzero'
                        ></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href='https://www.youtube.com/channel/UCrQIRRiwHoAjb0UeKi2PryQ/' target='/'>
                      <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M13.7045 2.24625C13.6475 2.02275 13.4855 1.854 13.3002 1.80225C12.9725 1.71 11.375 1.5 8 1.5C4.625 1.5 3.029 1.71 2.69825 1.80225C2.51525 1.85325 2.35325 2.022 2.2955 2.24625C2.21375 2.56425 2 3.897 2 6C2 8.103 2.21375 9.435 2.2955 9.7545C2.3525 9.97725 2.5145 10.146 2.699 10.197C3.029 10.29 4.625 10.5 8 10.5C11.375 10.5 12.9718 10.29 13.3018 10.1977C13.4847 10.1467 13.6468 9.978 13.7045 9.75375C13.7863 9.43575 14 8.1 14 6C14 3.9 13.7863 2.565 13.7045 2.24625ZM15.1572 1.8735C15.5 3.21 15.5 6 15.5 6C15.5 6 15.5 8.79 15.1572 10.1265C14.9667 10.8652 14.4095 11.4465 13.7037 11.643C12.422 12 8 12 8 12C8 12 3.58025 12 2.29625 11.643C1.5875 11.4435 1.031 10.863 0.84275 10.1265C0.5 8.79 0.5 6 0.5 6C0.5 6 0.5 3.21 0.84275 1.8735C1.03325 1.13475 1.5905 0.5535 2.29625 0.357C3.58025 -1.3411e-07 8 0 8 0C8 0 12.422 -1.3411e-07 13.7037 0.357C14.4125 0.5565 14.969 1.137 15.1572 1.8735ZM6.5 8.625V3.375L11 6L6.5 8.625Z'
                          fill='white'
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='rewarding_cup_img'>
                <img src={cup_img} alt='cup_img' />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RewardingLoyaltySec;
