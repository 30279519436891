import card_img_1 from "../assets/images/Homepage/card_img_1.png";
import card_img_2 from "../assets/images/Homepage/card_img_2.webp";
import card_img_3 from "../assets/images/Homepage/card_img_3.jpg";

export const LatestMarketData = [
  {
    id: 1,
    card_link: "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain",
    card_img: card_img_1,
    heading: "Connecting MetaMask to BNB Smart Chain",
    card_date: "20/10/2020"
  },
  {
    id: 2,
    card_link: "https://medium.com/@f0r3x_shark/animal-farm-governance-b223bc3f6330",
    card_img: card_img_2,
    heading: "Animal Farm Governance!",
    card_date: "5/12/2022"
  },
  {
    id: 3,
    card_link: "https://www.pr-inside.com/the-animal-farm-challenging-the-centralized-standard-to-benefit-investors-r4909333.htm",
    card_img: card_img_3,
    heading: "The Animal Farm: Challenging The Centralized Standard to Benefit Investors",
    card_date: "3/2/2023"
  },
]