import React from 'react';
import Container from 'react-bootstrap/Container';
import { Button } from './common/Button';
import { WebHeading } from './common/WebHeading';
import our_Arrow from '../assets/images/Homepage/our_Arrow.svg';

const OurVision = () => {
  return (
    <>
      <section className='our_vision_sec'>
        <Container>
          <div className='our_vision_row'>
            <div className='our_arrow_block'>
              <div className='arrows'>
                <a href='https://defi.animalfarm.app'>
                  <img src={our_Arrow} alt='our_Arrow' className='rotate' />
                </a>
              </div>
            </div>
            <div className='our_vision_text'>
              <div>
                <span>our vision</span>
                <WebHeading WebHeading='The Animal Farm' />
                <p>
                  Our vision is to make finance tools, traditionally reserved for the ultra-rich, accessible to all using decentralized protocols free from
                  centralized gatekeeping. Animal Farm offers genuine decentralized utility in market making, lending and yield aggregation. Unlike other
                  platforms, our users benefit from 0 deposit or withdrawal fees, and receive yield paid to them in top assets like WBTC, WETH, BNB and
                  stablecoins. This empowers users to earn like the wealthy by not only using our powerful tools but owning them with our innovative governance
                  structure.
                </p>
                <Button btnname='Launch App' href='https://defi.animalfarm.app' />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurVision;
