import React from 'react';
import Header from '../Components/Header';
import HeroSec from '../Components/HeroSec';
import OurVision from '../Components/OurVision';
import LatestMarket from '../Components/LatestMarket';
import VisitMarmarSec from '../Components/VisitMarmarSec';
import OurClientsSec from '../Components/OurClientsSec';
import OurAuditors from '../Components/OurAuditors';
import OurPartners from '../Components/OurPartners';
import RewardingLoyaltySec from '../Components/RewardingLoyaltySec';
import Footer from '../Components/Footer';

const HomePage = () => {
  return (
    <>
      <div className='main_wrapper'>
        <div className='bg_block'>
          <Header />
          <HeroSec />
        </div>
        <OurVision />
        <LatestMarket />
        <VisitMarmarSec />
        <OurClientsSec />
        <OurAuditors />
        <OurPartners />
        <RewardingLoyaltySec />
        <Footer />
      </div>
    </>
  )
}

export default HomePage;