import userimage_1 from "../assets/images/Homepage/userimage_1.jpg";
import userimage_2 from "../assets/images/Homepage/userimage_2.jpg";
import userimage_3 from "../assets/images/Homepage/userimage_3.jpg";
import userimage_4 from "../assets/images/Homepage/userimage_4.jpg";
import userimage_5 from "../assets/images/Homepage/userimage_5.png";
import userimage_6 from "../assets/images/Homepage/userimage_6.jpg";
import userimage_7 from "../assets/images/Homepage/userimage_7.jpg";

export const OurClientData = [
  {
    id: 2,
    details: "I've been following Forex Sharks projects for years now and it has completely changed the way I view investments. They've been the best performing part of my portfolio by a considerable margin. Needless to say my life has completely changed and I am going big on the Animal Farm. They say by their fruits you will know them, and there's nothing but success with Forex.",
    userimage: userimage_2,
    platform: "Telegram",
    uesremail: "@CrankyAndy"
  },
  {
    id: 3,
    details: "My investments in Forex Sharks projects have outperformed over 1k other projects I've invested in! So grateful to have found this incredible ecosystem.",
    userimage: userimage_3,
    platform: "Telegram",
    uesremail: "@NPC6388"
  },
  {
    id: 4,
    details: "I invested in the AFP and Dog token and also paired my Drip with BUSD and the dividends were incredible. Forex Shark is a genius. I'm going in very heavy Oct 18.",
    userimage: userimage_4,
    platform: "Telegram",
    uesremail: "@swolewhale"
  },
  {
    id: 5,
    details: "This team makes the only defi projects I trust with my money. I look forward to staking my crypto on animal farm as we navigate this bear market, and I sleep better knowing it will always be there when I need it.",
    userimage: userimage_5,
    platform: "Discord",
    uesremail: "CaptainCanuck#8451",
  },
  {
    id: 6,
    details: "I paid thousands for course enrollments elsewhere and the free information that Forex Shark made available for his previous projects outperformed everything shilled as 'alpha' in the paid courses by multiples. My positions continue growth along an exponential curve even in the depths of a bear market. Can't wait for the Animal Farm launch to kickstart the next bull in Forex Shark's ecosystem!",
    userimage: userimage_6,
    platform: "Telegram",
    uesremail: "@AlephTheNemesis",
  },
  {
    id: 7,
    details: "I discovered drip in August 2021 and it completely changed my outlook on crypto and defi. I have been profitable in drip while losing money in about every other project I was involved in. Super excited for the Animal Farm launch! Dogs and Pigs for life!",
    userimage: userimage_7,
    platform: "Telegram",
    uesremail: "@theory6453",
  },
]