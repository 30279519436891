import React from 'react';
import Container from 'react-bootstrap/Container';
import { WebHeading } from './common/WebHeading';
import { LatestMarketCard } from './common/LatestMarketCard';

const LatestMarket = () => {
  return (
    <>
      <section className='LatestMarket'>
        <Container>
          <WebHeading WebHeading="Latest Market News & Insights" />
          <div className='row card_center_row'>
            <LatestMarketCard num={3} />
          </div>
        </Container>
      </section>
    </>
  )
}

export default LatestMarket;